import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

export interface PaymentMethod {
    pay_id: number;
    pay_name: string;
    pay_email: string;
    merchant_id: string;
    check_desc: string;
    ccavenue_id: string;
    working_key: string;
    access_code: string;
    use_in_payment: string;
    bank_detail: string;
    status: string;
}

interface PaginationState<T> {
    items: T[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface PaymentMethodState {
    paymentMethods: PaginationState<PaymentMethod>;
}

const createPaginationState = <T>(): PaginationState<T> => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        paymentMethods: createPaginationState(),
    },
    getters: {
        paymentMethods: (state) => state.paymentMethods,
    },
    actions: {
        [Actions.GET_PAYMENT_METHODS]({state, commit}, {pageNo, pageSize, status}) {
            commit(Mutations.SET_ERROR, null);

            // Only reset pagination if filters change (not during pagination navigation)
            if (!pageNo) {
                commit(Mutations.RESET_PAYMENTMETHOD_PAGINATION);
            }

            // Merge the existing pageNo and filters
            const params = {
                pageNo: pageNo ? pageNo : state.pageNo || undefined, // Use passed pageNo or the one in state
                pageSize: pageSize ? pageSize : state.pageSize || undefined, // Use passed pageNo or the one in state
                status: status || undefined, // Include status if provided
            };
            return ApiService.query("/v2/paymentMethod", {params})
                .then((response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    const {data} = response;
                    commit(Mutations.SET_PAYMENTMETHODS, {
                        items: data?.data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        // [Actions.GET_PAYMENTMETHOD]({state, commit}, leadId) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.get(`/v2/lead/${leadId}`)
        //         .then(({ data }) => {
        //             commit(Mutations.SET_SELECTED_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.UPDATE_PAYMENTMETHOD]({state, commit}, { leadId, payload }) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.put(`/v2/lead/${leadId}`, payload)
        //         .then(({ data }) => {
        //             commit(Mutations.UPDATE_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.ADD_PAYMENTMETHOD]({state, commit}, payload) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.post(`/v2/lead`, payload)
        //         .then(({ data }) => {
        //             commit(Mutations.ADD_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.DELETE_PAYMENTMETHOD]({ commit }, leadId) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.delete(`/v2/lead/${leadId}`)
        //         .then(({data}) => {
        //             commit(Mutations.DELETE_LEAD, leadId);
        //             return data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.SET_SELECTED_PAYMENTMETHOD]({ commit }, lead) {
        //     commit(Mutations.SET_SELECTED_LEAD, lead);
        // },
        // [Actions.UPDATE_PAYMENTMETHOD_PAGENO]({ commit, dispatch }: any, pageNo: number) {
        //     commit(Mutations.SET_PAGENO, pageNo);
        //     // dispatch(Actions.GET_MATCH_ACTIVITY);
        // },
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        // [Mutations.UPDATE_PAYMENTMETHOD](state, updatedLead) {
        //     const index = state.leads?.items.findIndex(lead => lead.id === updatedLead.id);
        //     if (index !== -1) {
        //         state.leads.items[index] = updatedLead; // Update the role in the state
        //     }
        // },
        // [Mutations.ADD_PAYMENTMETHOD](state, newLead) {
        //     state.leads.items.unshift(newLead);
        // },
        [Mutations.SET_PAYMENTMETHODS](state, payload) {
            // state.leads = Array.isArray(leads) ? leads : [];
            state.paymentMethods.items = payload.items;
            state.paymentMethods.itemCount = payload.itemCount;
            state.paymentMethods.pageSize = payload.pageSize;
            state.paymentMethods.pageNo = payload.pageNo;
            state.paymentMethods.pageCount = payload.pageCount;
        },
        // [Mutations.DELETE_PAYMENTMETHOD](state, leadId) {
        //     state.leads.items = state.leads.items.filter((lead) => lead.id !== leadId);
        // },
        // [Mutations.SET_SELECTED_PAYMENTMETHOD](state, lead) {
        //     state.selectedLead = lead;
        // },
        // [Mutations.SET_PAYMENTMETHOD_PAGENO](state: LeadState, pageNo: number) {
        //     state.leads.pageNo = pageNo;
        // },
        [Mutations.RESET_PAYMENTMETHOD_PAGINATION](state: PaymentMethodState) {
            state.paymentMethods = createPaginationState();
        },

    },
};
