import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface Payment {
  payid: number,
  pmatri_id: string,
  pname: string,
  pemail: string,
  paddress: string,
  paymode: string,
  pactive_dt: Date,
  p_plan: string,
  plan_duration: number,
  profile: number,
  video: string,
  chat: string,
  p_no_contacts: number,
  p_amount: string,
  p_bank_detail: string,
  pay_id: string,
  p_msg: number,
  r_profile: number,
  r_cnt: number,
  r_sms: number,
  exp_date: Date,
  current_plan: string,
  franchise_comm_per: string,
  franchise_comm_amt: string,
  franchise_id: number,
  tax_name: string,
  tax_per: number,
}

interface PaginationState<T> {
  items: T[];
  itemCount: number;
  pageSize: number;
  pageNo: number;
  pageCount: number;
  loading: boolean;
}

interface PaymentState {
  payments: PaginationState<Payment>;
}

const createPaginationState = <T>(): PaginationState<T> => ({
  items: [],
  itemCount: 0,
  pageSize: 10,
  pageNo: 1,
  pageCount: 0,
  loading: false,
});

export default {
  state: {
    payments: createPaginationState(), // Store leads here
    selectedPayment: null, // Store selected lead for editing
    errors: {},
  },
  getters: {
    payments: (state) => state.payments,
    selectedPayment: (state) => state.selectedPayment,
  },actions: {
    [Actions.GET_PAYMENTS]({state, commit}, {pageNo, pageSize, status, paymentMethod, startDate, endDate}) {
      commit(Mutations.SET_ERROR, null);
      // Only reset pagination if filters change (not during pagination navigation)
      if (!pageNo) {
        commit(Mutations.RESET_PAGINATION);
      }

      // Merge the existing pageNo and filters
      const params = {
        pageNo: pageNo ? pageNo : state.pageNo || undefined, // Use passed pageNo or the one in state
        pageSize: pageSize ? pageSize : state.pageSize || undefined, // Use passed pageNo or the one in state
        // status: status || undefined, // Include status if provided
        paymentMethod: paymentMethod || undefined, // Include assignedTo if provided
        startDate: startDate || undefined, // Include startDate if provided
        endDate: endDate || undefined, // Include endDate if provided
      };

      return ApiService.query(`/v2/payment`, {params})
          .then((response)  => {
            const headersData = JSON.parse(response.headers["pagination"]);
            const {data} = response;
            commit(Mutations.SET_PAYMENTS, {
              items: data?.data,
              itemCount: headersData.ItemCount,
              pageSize: headersData.PageSize,
              pageNo: headersData.PageNo,
              pageCount: headersData.PageCount,
            });
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.error);
          });
    },
    [Actions.CREATE_PAYMENT]({state, commit, dispatch}, payload) {
      commit(Mutations.SET_ERROR, null);
      return ApiService.post(`/v2/payment`, payload)
          .then(({ data }) => {
            // commit(Mutations.ADD_LEAD, data?.data);
            commit('updateUserStatusToPaid', { index_id: data?.data?.index_id, status: 'Paid' });
          })
          .catch(({response}) => {
            commit(Mutations.SET_ERROR, response?.data?.meta?.message);
          });
    },
    [Actions.UPDATE_PAYMENTS_PAGENO]({ commit, dispatch }: any, pageNo: number) {
      commit(Mutations.SET_PAYMENTS_PAGENO, pageNo);
      // dispatch(Actions.GET_MATCH_ACTIVITY);
    },
  },
  mutations: {

    [Mutations.SET_PAYMENTS](state, payload) {
      // state.leads = Array.isArray(leads) ? leads : [];
      state.payments.items = payload.items;
      state.payments.itemCount = payload.itemCount;
      state.payments.pageSize = payload.pageSize;
      state.payments.pageNo = payload.pageNo;
      state.payments.pageCount = payload.pageCount;
    },
    [Mutations.SET_PAYMENTS_PAGENO](state: PaymentState, pageNo: number) {
      state.payments.pageNo = pageNo;
    },
    [Mutations.RESET_PAGINATION](state: PaymentState) {
      state.payments = createPaginationState();
    },
    [Mutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
  },
};
