import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {User} from "@/store/modules/UserModule";

export default {
    state: {
        permissions: [], // Store permissions here
        selectedPermission: null, // Store selected permission for editing
    },
    getters: {
        permissions: (state) => state.permissions,
        selectedPermission: (state) => state.selectedPermission,
    },
    actions: {
        [Actions.GET_PERMISSIONS]({ commit }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get("/v2/permission")
                .then(({data}) => {
                    commit(Mutations.SET_PERMISSIONS, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.UPDATE_PERMISSION]({state, commit}, { permissionId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.put(`/v2/permission/${permissionId}`, payload)
                .then(({ data }) => {
                    commit(Mutations.UPDATE_PERMISSION, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.ADD_PERMISSION]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`/v2/permission`, payload)
                .then(({ data }) => {
                    commit(Mutations.ADD_PERMISSION, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.DELETE_PERMISSION]({ commit }, permissionId) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/v2/permission/${permissionId}`)
                .then(({data}) => {
                    commit(Mutations.DELETE_PERMISSION, permissionId);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        }
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [Mutations.UPDATE_PERMISSION](state, updatedPermission) {
            const index = state.permissions.findIndex(permission => permission.id === updatedPermission.id);
            if (index !== -1) {
                state.permissions[index] = updatedPermission; // Update the permission in the state
            }
        },
        [Mutations.ADD_PERMISSION](state, newPermission) {
            state.permissions.push(newPermission);
        },
        [Mutations.SET_PERMISSIONS](state, permissions) {
            state.permissions = Array.isArray(permissions) ? permissions : [];
        },
        [Mutations.DELETE_PERMISSION](state, permissionId) {
            state.permissions = state.permissions.filter((permission) => permission.id !== permissionId);
        },
    },
};
