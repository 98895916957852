import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {User} from "@/store/modules/UserModule";

export default {
    state: {
        roles: [], // Store roles here
        selectedRole: null, // Store selected role for editing
    },
    getters: {
        roles: (state) => state.roles,
        selectedRole: (state) => state.selectedRole,
    },
    actions: {
        [Actions.GET_ROLES]({ commit }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get("/v2/role")
                .then(({data}) => {
                    commit(Mutations.SET_ROLES, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.UPDATE_ROLE]({state, commit}, { roleId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.put(`/v2/role/${roleId}`, payload)
                .then(({ data }) => {
                    commit(Mutations.UPDATE_ROLE, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.ADD_ROLE]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`/v2/role`, payload)
                .then(({ data }) => {
                    commit(Mutations.ADD_ROLE, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.DELETE_ROLE]({ commit }, roleId) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/v2/role/${roleId}`)
                .then(({data}) => {
                    commit(Mutations.DELETE_ROLE, roleId);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.SET_SELECTED_ROLE]({ commit }, role) {
            commit(Mutations.SET_SELECTED_ROLE, role);
        },
        [Actions.ASSIGN_PERMISSIONS_TO_ROLE]({ commit }, { roleId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`v2/role/${roleId}/permissions`, payload)
                .then(({data}) => {
                    commit(Mutations.UPDATE_ROLE_PERMISSION, data?.data);
                    // commit(Mutations.SET_SELECTED_ROLE, data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [Mutations.UPDATE_ROLE](state, updatedRole) {
            const index = state.roles.findIndex(role => role.id === updatedRole.id);
            if (index !== -1) {
                state.roles[index] = updatedRole; // Update the role in the state
            }
        },
        [Mutations.ADD_ROLE](state, newRole) {
            state.roles.push(newRole);
        },
        [Mutations.SET_ROLES](state, roles) {
            state.roles = Array.isArray(roles) ? roles : [];
        },
        [Mutations.DELETE_ROLE](state, roleId) {
            state.roles = state.roles.filter((role) => role.id !== roleId);
        },
        [Mutations.SET_SELECTED_ROLE](state, role) {
            state.selectedRole = role;
        },
        [Mutations.UPDATE_ROLE_PERMISSION](state, updatedRole) {
            const index = state.roles.findIndex((role) => role.id === updatedRole.id);
            if (index !== -1) {
                // Replace the object in the roles array to trigger reactivity
                state.roles.splice(index, 1, { ...state.roles[index], ...updatedRole });
            }

            // Update selectedRole if it matches the updated role
            if (state.selectedRole?.id === updatedRole.id) {
                state.selectedRole = { ...state.selectedRole, ...updatedRole };
            }
        },
    },
};
