import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import Religion from "@/class/Religion";
import Mtongue from "@/class/Mtongue";
import Caste from "@/class/Caste";
import Country from "@/class/Country";
import {constants} from "@/core/services/Constants";
import MatchBy from "@/class/MatchBy";
import {Mutation} from "vuex-module-decorators";
import {User} from "@/store/modules/UserModule";
import store from "@/store";
import EduDetail from "@/class/EduDetail";
import Occupation from "@/class/Occupation";

interface PaginationState {
    items: User[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface UserState {
    errors: unknown;
    users: PaginationState;
    matchUsers: PaginationState;
}

const createPaginationState = (): PaginationState => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        errors: {},
        search:  {
            searchText: null,
            gender: [] as string[],
            mstatus: [] as string[],
            age_frm: null,
            age_to: null,
            height: null,
            height_to: null,
            religion: [] as Religion[],
            caste: [] as Caste[],
            mtongue: [] as Mtongue[],
            country: [] as Country[],
            status: [],
            part_smoke: [],
            part_drink: [],
            part_diet: [],
            part_edu: [] as EduDetail[],
            part_occupation: [] as Occupation[],
            part_resi_status: [],
            withPhoto: null
        },
        matchSearch:  {
            searchText: null,
            gender: [] as string[],
            mstatus: [] as string[],
            age_frm: null,
            age_to: null,
            height: null,
            height_to: null,
            religion: [] as Religion[],
            caste: [] as Caste[],
            mtongue: [] as Mtongue[],
            country: [] as Country[],
            status: [],
            part_smoke: [],
            part_drink: [],
            part_diet: [],
            part_edu: [] as EduDetail[],
            part_occupation: [] as Occupation[],
            part_resi_status: [],
            withPhoto: null

        },
        matchBy: [] as MatchBy[],
        users: createPaginationState(),
        matchUsers: createPaginationState(),
    },

    getters: {

        currentSearch(state) {
            return state.search;
        },
        currentMatchSearch(state) {
            return state.matchSearch;
        },
        users(state) {
            return state.users;
        },
        matchUsers(state) {
            return state.matchUsers;
        },
        currentMatchBy(state) {
            return state.matchBy;
        },


        // getErrors() {
        //     return this.errors;
        // }
    },
    actions: {
        [Actions.SEARCH_USER]({ state, commit }) {
            commit(Mutations.SET_ERROR, null);
// searchText, gender, mstatus, age_frm, age_to, height, height_to, religion, caste, mtongue, country, fstatus, status, part_smoke, part_drink, part_diet, part_edu, part_occupation, part_resi_status
            let new_payload = {};

            new_payload['searchText'] = state?.search?.searchText;

            new_payload['gender'] = state?.search?.gender;

            const mstatus = state?.search?.mstatus?.filter(obj => obj !== constants.ANY);
            new_payload['mstatus'] = mstatus?.map(function(item) {
                return item;
            });

            new_payload['age_frm'] = state?.search?.age_frm;
            new_payload['age_to'] = state?.search?.age_to;

            new_payload['height'] = state?.search?.height?.value;
            new_payload['height_to'] = state?.search?.height_to?.value;

            const religion = state?.search?.religion?.filter(obj => obj?.religion_id !== 0);
            new_payload['religion'] = religion?.map(function(item) {
                return item.religion_id;
            });
            const caste = state?.search?.caste?.filter(obj => obj.caste_id !== 0);
            new_payload['caste'] = caste?.map(function(item) {
                return item.caste_id;
            });

            const mtongue = state?.search?.mtongue?.filter(obj => obj?.mtongue_id !== 0);
            new_payload['mtongue'] = mtongue?.map(function(item) {
                return item?.mtongue_id;
            });

            const country = state?.search?.country?.filter(obj => obj?.country_id !== 0);
            new_payload['country'] = country?.map(function(item) {
                return item?.country_id;
            });
            // fstatus
            const status = state?.search?.status?.filter(obj => obj !== constants.ANY);
            new_payload['status'] = status?.map(function(item) {
                return item;
            });
            
            new_payload['part_smoke'] = state?.search?.part_smoke;
            new_payload['part_drink'] = state?.search?.part_drink;
            new_payload['part_diet'] = state?.search?.part_diet;

            const edu = state?.search?.part_edu?.filter(obj => obj?.edu_id !== 0);
            new_payload['part_edu'] = edu?.map(function(item) {
                return item?.edu_id;
            });

            const ocp = state?.search?.part_occupation?.filter(obj => obj?.ocp_id !== 0);
            new_payload['part_occupation'] = ocp?.map(function(item) {
                return item?.ocp_id;
            });
            new_payload['part_resi_status'] = state?.search?.part_resi_status;

            new_payload['withPhoto'] = state?.search?.withPhoto;

            new_payload['pageNo'] = state?.users?.pageNo;

            return ApiService.query("/v2/user", { params: new_payload })
                .then(async(response) => {
                    // const headersData = JSON.parse(response.headers["pagination"]);
                    // commit(Mutations.SET_USERS, {
                    //     items: response.data,
                    //     itemCount: headersData.ItemCount,
                    //     pageSize: headersData.PageSize,
                    //     pageNo: headersData.PageNo,
                    //     pageCount: headersData.PageCount,
                    // });
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.SEARCH_MATCH_USER]({ state, commit }) {
            commit(Mutations.SET_ERROR, null);
// searchText, gender, mstatus, age_frm, age_to, height, height_to, religion, caste, mtongue, country, fstatus, status, part_smoke, part_drink, part_diet, part_edu, part_occupation, part_resi_status
            let new_payload = {};

            new_payload['searchText'] = state?.matchSearch?.searchText;

            new_payload['gender'] = state?.matchSearch?.gender;

            const mstatus = state?.matchSearch?.mstatus?.filter(obj => obj !== constants.ANY);
            new_payload['mstatus'] = mstatus?.map(function(item) {
                return item;
            });

            new_payload['age_frm'] = state?.matchSearch?.age_frm;
            new_payload['age_to'] = state?.matchSearch?.age_to;

            new_payload['height'] = state?.matchSearch?.height?.value;
            new_payload['height_to'] = state?.matchSearch?.height_to?.value;

            const religion = state?.matchSearch?.religion?.filter(obj => obj?.religion_id !== 0);
            new_payload['religion'] = religion?.map(function(item) {
                return item.religion_id;
            });
            const caste = state?.matchSearch?.caste?.filter(obj => obj.caste_id !== 0);
            new_payload['caste'] = caste?.map(function(item) {
                return item.caste_id;
            });

            const mtongue = state?.matchSearch?.mtongue?.filter(obj => obj?.mtongue_id !== 0);
            new_payload['mtongue'] = mtongue?.map(function(item) {
                return item?.mtongue_id;
            });

            const country = state?.matchSearch?.country?.filter(obj => obj?.country_id !== 0);
            new_payload['country'] = country?.map(function(item) {
                return item?.country_id;
            });
            // fstatus
            const status = state?.matchSearch?.status?.filter(obj => obj !== constants.ANY);
            new_payload['status'] = status?.map(function(item) {
                return item;
            });

            new_payload['part_smoke'] = state?.matchSearch?.part_smoke;
            new_payload['part_drink'] = state?.matchSearch?.part_drink;
            new_payload['part_diet'] = state?.matchSearch?.part_diet;

            const edu = state?.matchSearch?.part_edu?.filter(obj => obj?.edu_id !== 0);
            new_payload['part_edu'] = edu?.map(function(item) {
                return item?.edu_id;
            });

            const ocp = state?.matchSearch?.part_occupation?.filter(obj => obj?.ocp_id !== 0);
            new_payload['part_occupation'] = ocp?.map(function(item) {
                return item?.ocp_id;
            });
            new_payload['part_resi_status'] = state?.matchSearch?.part_resi_status;

            new_payload['withPhoto'] = state?.matchSearch?.withPhoto;


            new_payload['pageNo'] = state?.matchUsers?.pageNo;

            return ApiService.query("/admin/users/filterUser", { params: new_payload })
                .then(async(response) => {
                    // const headersData = JSON.parse(response.headers["pagination"]);
                    // commit(Mutations.SET_USERS, {
                    //     items: response.data,
                    //     itemCount: headersData.ItemCount,
                    //     pageSize: headersData.PageSize,
                    //     pageNo: headersData.PageNo,
                    //     pageCount: headersData.PageCount,
                    // });
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.UPDATE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_PAGENO, pageNo);
            // dispatch(Actions.SEARCH_USER);
        },
        [Actions.UPDATE_MATCH_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_MATCH_PAGENO, pageNo);
            // dispatch(Actions.SEARCH_USER);
        },
        [Actions.SET_USERS]({ commit, dispatch }: any, data) {
            commit(Mutations.SET_USERS, data);
        },

        [Actions.CLEAR_USERS]({ commit }) {
            commit(Mutations.PURGE_USERS);
        },
        [Actions.CLEAR_MATCH_USERS]({ commit }) {
            commit(Mutations.PURGE_MATCH_USERS);
        },
        [Actions.UPDATE_SEARCH]({ commit, dispatch }: any, search: UserState) {
            commit(Mutations.SET_SEARCH, search);
            commit(Mutations.RESET_PAGINATION);
            // dispatch(Actions.SEARCH);
        },
        [Actions.STORE_SEARCH]({ commit }, payload) {
            commit(Mutations.SET_SEARCH, payload);
        },
        [Actions.CLEAR_SEARCH]({ commit }) {
            commit(Mutations.PURGE_SEARCH);
        },
        [Actions.CLEAR_MATCH_SEARCH]({ commit }) {
            commit(Mutations.PURGE_MATCH_SEARCH);
        },
        [Actions.STORE_MATCH_BY]({ commit }, payload) {
            commit(Mutations.SET_MATCH_BY, payload);
        },
        [Actions.CLEAR_MATCH_BY]({ commit }) {
            commit(Mutations.PURGE_MATCH_BY);
        },

        [Actions.SEARCH]({ state, commit }, payload) {

            return ApiService.query("/admin/users/searchUser", { params: payload})
                .then((response) => {
                    // commit(Mutations.SET_USERS, response.data);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
    },
    mutations: {
        [Mutations.PURGE_SEARCH](state) {
            state.search = {};
        },
        [Mutations.PURGE_MATCH_SEARCH](state) {
            state.matchSearch = {};
        },
        [Mutations.SET_SEARCH](state, search) {
            state.search = search;
        },
        [Mutations.SET_MATCH_SEARCH](state, search) {
            state.matchSearch = search;
        },
        [Mutations.SET_PAGENO](state: UserState, pageNo: number) {
            state.users.pageNo = pageNo;
        },
        [Mutations.SET_MATCH_PAGENO](state: UserState, pageNo: number) {
            state.matchUsers.pageNo = pageNo;
        },
        [Mutations.RESET_PAGINATION](state: UserState) {
            state.users = createPaginationState();
        },
        [Mutations.RESET_MATCH_PAGINATION](state: UserState) {
            state.matchUsers = createPaginationState();
        },
        [Mutations.SET_USERS](state: UserState, payload: { items: User[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.users.items = payload.items;
            state.users.itemCount = payload.itemCount;
            state.users.pageSize = payload.pageSize;
            state.users.pageNo = payload.pageNo;
            state.users.pageCount = payload.pageCount;
        },
        [Mutations.SET_MATCH_USERS](state: UserState, payload: { items: User[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.matchUsers.items = payload.items;
            state.matchUsers.itemCount = payload.itemCount;
            state.matchUsers.pageSize = payload.pageSize;
            state.matchUsers.pageNo = payload.pageNo;
            state.matchUsers.pageCount = payload.pageCount;
        },
        [Mutations.PURGE_USERS](state) {
            state.users = createPaginationState();
        },
        [Mutations.PURGE_MATCH_USERS](state) {
            state.matchUsers = createPaginationState();
        },
        [Mutations.PURGE_MATCH_BY](state) {
            state.matchBy = [];
        },
        [Mutations.SET_MATCH_BY](state, matchBy) {
            state.matchBy = matchBy;
        },
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
    }
};
