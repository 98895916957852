import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
interface Lead {
    id: number; // Unique identifier for the lead
    name: string; // Name of the lead
    email: string; // Email address
    phone: {

    }; // Phone details
    source: string; // Source of the lead (e.g., referral, advertisement)
    status: string; // Status of the lead (e.g., active, closed)
    createdBy: string | Date; // Creator of the lead (Date object or ISO string)
    assignedTo: number | null; // ID of the assigned user, null if unassigned
}

interface Note {
    id: number; // Unique identifier for the comment
    leadId: number; // ID of the lead to which this comment belongs
    createdBy: string; // Creator of the comment
    content: string; // Content of the comment
    createdAt: string | Date; // Created date of the comment
}

interface PaginationState<T> {
    items: T[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface LeadState {
    leads: PaginationState<Lead>;
}

interface NoteState {
    notes: PaginationState<Note>;
}

const createPaginationState = <T>(): PaginationState<T> => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        leads: createPaginationState(), // Store leads here
        notes: createPaginationState(), // Store lead comments here
        selectedLead: null, // Store selected lead for editing
    },
    getters: {
        leads: (state) => state.leads,
        notes: (state) => state.notes,
        selectedLead: (state) => state.selectedLead,
    },
    actions: {
        [Actions.GET_LEADS]({state, commit}, {pageNo, pageSize, status, assignedTo, startDate, endDate}) {
            commit(Mutations.SET_ERROR, null);

            // Only reset pagination if filters change (not during pagination navigation)
            if (!pageNo) {
                commit(Mutations.RESET_PAGINATION);
            }

            // Merge the existing pageNo and filters
            const params = {
                pageNo: pageNo ? pageNo : state.pageNo || undefined, // Use passed pageNo or the one in state
                pageSize: pageSize ? pageSize : state.pageSize || undefined, // Use passed pageNo or the one in state
                status: status || undefined, // Include status if provided
                assignedTo: assignedTo || undefined, // Include assignedTo if provided
                startDate: startDate || undefined, // Include startDate if provided
                endDate: endDate || undefined, // Include endDate if provided
            };
            return ApiService.query("/v2/lead", {params})
                .then((response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    const {data} = response;
                    commit(Mutations.SET_LEADS, {
                        items: data?.data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.GET_LEAD]({state, commit}, leadId) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/v2/lead/${leadId}`)
                .then(({ data }) => {
                    commit(Mutations.SET_SELECTED_LEAD, data?.data);
                    return data?.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.UPDATE_LEAD]({state, commit}, { leadId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.put(`/v2/lead/${leadId}`, payload)
                .then(({ data }) => {
                    commit(Mutations.UPDATE_LEAD, data?.data);
                    return data?.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.ADD_LEAD]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`/v2/lead`, payload)
                .then(({ data }) => {
                    commit(Mutations.ADD_LEAD, data?.data);
                    return data?.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.DELETE_LEAD]({ commit }, leadId) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/v2/lead/${leadId}`)
                .then(({data}) => {
                    commit(Mutations.DELETE_LEAD, leadId);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.SET_SELECTED_LEAD]({ commit }, lead) {
            commit(Mutations.SET_SELECTED_LEAD, lead);
        },
        [Actions.UPDATE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_PAGENO, pageNo);
            // dispatch(Actions.GET_MATCH_ACTIVITY);
        },

        [Actions.GET_NOTES]({ state, commit }, { leadId, pageNo, pageSize }) {
            commit(Mutations.SET_ERROR, null);

            // Merge the existing pageNo and filters
            const params = {
                leadId,
                pageNo: pageNo || state.notes.pageNo || 1, // Use passed pageNo or the one in state
                pageSize: pageSize || state.notes.pageSize || 10, // Use passed pageSize or the one in state
            };

            return ApiService.query(`/v2/lead/${leadId}/notes`, { params })
                .then((response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    const { data } = response;
                    commit(Mutations.SET_NOTES, {
                        items: data?.data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                })
                .catch(({ response }) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.ADD_NOTE]({state, commit}, { leadId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`/v2/lead/${leadId}/notes`, payload)
                .then(({ data }) => {
                    commit(Mutations.ADD_NOTE, data?.data);
                    return data?.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.DELETE_NOTE]({ commit }, {leadId, followup, noteId}) {
            commit(Mutations.SET_ERROR, null);
            const resource = `/v2/${leadId ? 'lead' : followup ? 'followup' : null}/${leadId}/notes/${noteId}`;
            return ApiService.delete(resource)
                .then(({data}) => {
                    commit(Mutations.DELETE_NOTE, noteId);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.UPDATE_NOTE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_NOTE_PAGENO, pageNo);
            // dispatch(Actions.GET_MATCH_ACTIVITY);
        },

    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [Mutations.UPDATE_LEAD](state, updatedLead) {
            const index = state.leads?.items.findIndex(lead => lead.id === updatedLead.id);
            if (index !== -1) {
                state.leads.items[index] = updatedLead; // Update the role in the state
            }
        },
        [Mutations.ADD_LEAD](state, newLead) {
            state.leads.items.unshift(newLead);
        },
        [Mutations.SET_LEADS](state, payload) {
            // state.leads = Array.isArray(leads) ? leads : [];
            state.leads.items = payload.items;
            state.leads.itemCount = payload.itemCount;
            state.leads.pageSize = payload.pageSize;
            state.leads.pageNo = payload.pageNo;
            state.leads.pageCount = payload.pageCount;
        },
        [Mutations.DELETE_LEAD](state, leadId) {
            state.leads.items = state.leads.items.filter((lead) => lead.id !== leadId);
        },
        [Mutations.SET_SELECTED_LEAD](state, lead) {
            state.selectedLead = lead;
        },
        [Mutations.SET_PAGENO](state: LeadState, pageNo: number) {
            state.leads.pageNo = pageNo;
        },
        [Mutations.RESET_PAGINATION](state: LeadState) {
            state.leads = createPaginationState();
        },

        [Mutations.ADD_NOTE](state, newNote) {
            state.notes.items.unshift(newNote);
        },
        [Mutations.SET_NOTES](state, payload) {
            state.notes.items = payload.items;
            state.notes.itemCount = payload.itemCount;
            state.notes.pageSize = payload.pageSize;
            state.notes.pageNo = payload.pageNo;
            state.notes.pageCount = payload.pageCount;
        },
        [Mutations.DELETE_NOTE](state, noteId) {
            state.notes.items = state.notes.items.filter((note) => note.id !== noteId);
        },
        [Mutations.SET_SELECTED_NOTE](state, note) {
            state.selectedNote = note;
        },
        [Mutations.SET_NOTE_PAGENO](state: NoteState, pageNo: number) {
            state.notes.pageNo = pageNo;
        },
        [Mutations.RESET_NOTE_PAGINATION](state: NoteState) {
            state.notes = createPaginationState();
        },
    },
};
