import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

export default {
    state: {
        admins: [], // Store roles here
        selectedAdmin: null, // Store selected role for editing
    },
    getters: {
        admins: (state) => state.admins,
        selectedAdmin: (state) => state.selectedAdmin,
    },
    actions: {
        [Actions.GET_ADMINS]({ commit }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get("/v2/adminUser")
                .then(({data}) => {
                    commit(Mutations.SET_ADMINS, data?.data);
                    return data?.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.UPDATE_ADMIN]({state, commit}, { adminId, payload }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.put(`/v2/adminUser/${adminId}`, payload)
                .then(({ data }) => {
                    commit(Mutations.UPDATE_ADMIN, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.ADD_ADMIN]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post(`/v2/adminUser`, payload)
                .then(({ data }) => {
                    commit(Mutations.ADD_ADMIN, data?.data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.DELETE_ADMIN]({ commit }, adminId) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/v2/adminUser/${adminId}`)
                .then(({data}) => {
                    commit(Mutations.DELETE_ADMIN, adminId);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        [Actions.SET_SELECTED_ADMIN]({ commit }, admin) {
            commit(Mutations.SET_SELECTED_ADMIN, admin);
        },
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [Mutations.UPDATE_ADMIN](state, updatedAdmin) {
            const index = state.admins.findIndex(admin => admin.id === updatedAdmin.id);
            if (index !== -1) {
                state.admins[index] = updatedAdmin; // Update the role in the state
            }
        },
        [Mutations.ADD_ADMIN](state, newAdmin) {
            state.admins.push(newAdmin);
        },
        [Mutations.SET_ADMINS](state, admins) {
            state.admins = Array.isArray(admins) ? admins : [];
        },
        [Mutations.DELETE_ADMIN](state, adminId) {
            state.admins = state.roles.filter((admin) => admin.id !== adminId);
        },
        [Mutations.SET_SELECTED_ADMIN](state, admin) {
            state.selectedAdmin = admin;
        },
    },
};
