import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Site from "@/class/Site";

export interface SiteInfo {
    errors: unknown;
    site: Site;
}

export default {
    state: {
        site:  Site,
    },
    getters: {
        getSite: (state) => state.site,
    },
    actions: {
        [Actions.SITE_CONFIG]({ commit }) {
            commit(Mutations.SET_ERROR);
            return ApiService.get("/site")
                .then(({data}) => {
                    commit(Mutations.SET_SITE_CONFIG, data);
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
    },
    mutations: {
        [Mutations.SET_SITE_CONFIG](site) {
            this.site = site;
        },
        [Mutations.SET_ERROR](error) {
            this.errors = {...error};
        },
    },
};
