export default class Site {
    id: number;
    web_name: string;
    web_frienly_name: string;
    web_logo_path: string;
    contact_no: string;
    title: string;
    description: string;
    keywords: string;
    favicon: string;
    google_analytics_code: string;
    footer: string;
    from_email: string;
    to_email: string;
    feedback_email: string;
    contact_email: string;
    watermark: string;
    top_line1: string;
    top_line2: string;
    top_line3: string;
    facebook: string;
    twitter: string;
    linkedin: string;
    google: string;
    banner1: string;
    banner2: string;
    banner3: string;
    tax_yes_no: string;
    tax_name: string;
    tax_per: string;
    client_id: string;
    web_appkey: string;

    constructor(
        id: number,
        web_name: string,
        web_frienly_name: string,
        web_logo_path: string,
        contact_no: string,
        title: string,
        description: string,
        keywords: string,
        favicon: string,
        google_analytics_code: string,
        footer: string,
        from_email: string,
        to_email: string,
        feedback_email: string,
        contact_email: string,
        watermark: string,
        top_line1: string,
        top_line2: string,
        top_line3: string,
        facebook: string,
        twitter: string,
        linkedin: string,
        google: string,
        banner1: string,
        banner2: string,
        banner3: string,
        tax_yes_no: string,
        tax_name: string,
        tax_per: string,
        client_id: string,
        web_appkey: string
    ) {
        this.id = id;
        this.web_name = web_name;
        this.web_frienly_name = web_frienly_name;
        this.web_logo_path = web_logo_path;
        this.contact_no = contact_no;
        this.title = title;
        this.description = description;
        this.keywords = keywords;
        this.favicon = favicon;
        this.google_analytics_code = google_analytics_code;
        this.footer = footer;
        this.from_email = from_email;
        this.to_email = to_email;
        this.feedback_email = feedback_email;
        this.contact_email = contact_email;
        this.watermark = watermark;
        this.top_line1 = top_line1;
        this.top_line2 = top_line2;
        this.top_line3 = top_line3;
        this.facebook = facebook;
        this.twitter = twitter;
        this.linkedin = linkedin;
        this.google = google;
        this.banner1 = banner1;
        this.banner2 = banner2;
        this.banner3 = banner3;
        this.tax_yes_no = tax_yes_no;
        this.tax_name = tax_name;
        this.tax_per = tax_per;
        this.client_id = client_id;
        this.web_appkey = web_appkey;
    }
}
