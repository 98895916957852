import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import Religion from "@/class/Religion";
import Mtongue from "@/class/Mtongue";
import Caste from "@/class/Caste";
import Country from "@/class/Country";
import {constants} from "@/core/services/Constants";
import MatchBy from "@/class/MatchBy";
import {Mutation} from "vuex-module-decorators";
import {User} from "@/store/modules/UserModule";
import store from "@/store";
import EduDetail from "@/class/EduDetail";
import Occupation from "@/class/Occupation";

interface PaginationState {
    items: User[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface UserState {
    matches: PaginationState;
    // users: PaginationState;
    // matchUsers: PaginationState;
}

const createPaginationState = (): PaginationState => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        matchUser: {} as User,
        lastMatchUser: {} as User,
        matchPayload:  {
            searchText: null,
            gender: [] as string[],
            mstatus: [] as string[],
            age_frm: null,
            age_to: null,
            height: null,
            height_to: null,
            religion: [] as Religion[],
            caste: [] as Caste[],
            mtongue: [] as Mtongue[],
            country: [] as Country[],
            status: [],
            part_smoke: [],
            part_drink: [],
            part_diet: [],
            part_edu: [] as EduDetail[],
            part_occupation: [] as Occupation[],
            part_resi_status: [],
            withPhoto: null

        },
        // matchBy: [] as MatchBy[],
        matches: createPaginationState(),
    },


    getters: {
        matchUser(state) {
            return state.matchUser;
        },
        lastMatchUser(state) {
            return state.lastMatchUser;
        },
        matchPayload(state) {
            return state.matchPayload;
        },
        matches(state) {
            return state.matches;
        },
    },
    actions: {
        [Actions.SET_MATCH_USER]({ commit }, user) {
            commit(Mutations.SET_MATCH_USER, user);
        },
        [Actions.SET_LAST_MATCH_USER]({ commit }, user) {
            commit(Mutations.SET_LAST_MATCH_USER, user);
        },
        [Actions.SEARCH_MATCHES]({ state, commit }) {
            commit(Mutations.SET_ERROR, null);
// searchText, gender, mstatus, age_frm, age_to, height, height_to, religion, caste, mtongue, country, fstatus, status, part_smoke, part_drink, part_diet, part_edu, part_occupation, part_resi_status
            let new_payload = {};

            new_payload['searchText'] = state?.matchPayload?.searchText;

            new_payload['gender'] = state?.matchPayload?.gender;

            const mstatus = state?.matchPayload?.mstatus?.filter(obj => obj !== constants.ANY);
            new_payload['mstatus'] = mstatus?.map(function(item) {
                return item;
            });

            new_payload['age_frm'] = state?.matchPayload?.age_frm;
            new_payload['age_to'] = state?.matchPayload?.age_to;

            new_payload['height'] = state?.matchPayload?.height?.value;
            new_payload['height_to'] = state?.matchPayload?.height_to?.value;

            const religion = state?.matchPayload?.religion?.filter(obj => obj?.religion_id !== 0);
            new_payload['religion'] = religion?.map(function(item) {
                return item.religion_id;
            });
            const caste = state?.matchPayload?.caste?.filter(obj => obj.caste_id !== 0);
            new_payload['caste'] = caste?.map(function(item) {
                return item.caste_id;
            });

            const mtongue = state?.matchPayload?.mtongue?.filter(obj => obj?.mtongue_id !== 0);
            new_payload['mtongue'] = mtongue?.map(function(item) {
                return item?.mtongue_id;
            });

            const country = state?.matchPayload?.country?.filter(obj => obj?.country_id !== 0);
            new_payload['country'] = country?.map(function(item) {
                return item?.country_id;
            });
            // fstatus
            const status = state?.matchPayload?.status?.filter(obj => obj !== constants.ANY);
            new_payload['status'] = status?.map(function(item) {
                return item;
            });

            new_payload['part_smoke'] = state?.matchPayload?.part_smoke;
            new_payload['part_drink'] = state?.matchPayload?.part_drink;
            new_payload['part_diet'] = state?.matchPayload?.part_diet;

            const edu = state?.matchPayload?.part_edu?.filter(obj => obj?.edu_id !== 0);
            new_payload['part_edu'] = edu?.map(function(item) {
                return item?.edu_id;
            });

            const ocp = state?.matchPayload?.part_occupation?.filter(obj => obj?.ocp_id !== 0);
            new_payload['part_occupation'] = ocp?.map(function(item) {
                return item?.ocp_id;
            });
            new_payload['part_resi_status'] = state?.matchPayload?.part_resi_status;

            new_payload['withPhoto'] = state?.matchPayload?.withPhoto;


            new_payload['pageNo'] = state?.matches?.pageNo;

            return ApiService.query("/v2/user", { params: new_payload })
                .then(async(response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    commit(Mutations.SET_MATCHES, {
                        items: response.data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.UPDATE_MATCHES_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_MATCHES_PAGENO, pageNo);
            // dispatch(Actions.SEARCH_USER);
        },
        [Actions.SET_MATCH_PAYLOAD]({ commit, dispatch }: any, data) {
            commit(Mutations.SET_MATCH_PAYLOAD, data);
        },
        [Actions.CLEAR_MATCH_USER]({ commit }) {
            commit(Mutations.PURGE_MATCH_USER);
            commit(Mutations.PURGE_MATCHES);
        },
        [Actions.CLEAR_MATCHES]({ commit }) {
            commit(Mutations.PURGE_MATCHES);
        },
        [Actions.SET_MATCHES]({ commit, dispatch }: any, data) {
            commit(Mutations.SET_MATCH_USERS, data);
        },
        [Actions.UPDATE_MATCHES]({ commit, dispatch }: any, search: UserState) {
            commit(Mutations.SET_MATCHES, search);
            commit(Mutations.RESET_MATCHES_PAGINATION);
            // dispatch(Actions.SEARCH);
        },
        async [Actions.FETCH_SELECTED_MATCH_USER]({state, commit}, id) {
            const existingUser = state.matchUser;

            // If user is already set, skip API call
            if (existingUser && existingUser.index_id === parseInt(id)) {
                return existingUser;
            }
            try {
                return ApiService.get(`/admin/users/${id}`)
                    .then((response) => {
                        commit(Mutations.SET_MATCH_USER, response?.data);
                        return response?.data;
                    })
                    .catch(({response}) => {
                        commit(Mutations.SET_ERROR, response.data.error);
                    });
            } catch (error) {
                console.error("Error fetching user details:", error);
                throw error;
            }
        },
    },
    mutations: {
        [Mutations.SET_MATCH_USER](state, user) {
            state.matchUser = user;
        },
        [Mutations.SET_LAST_MATCH_USER](state, user) {
            state.lastMatchUser = user;
        },
        // [Mutations.PURGE_SEARCH](state) {
        //     state.search = {};
        // },
        // [Mutations.PURGE_MATCH_SEARCH](state) {
        //     state.matchSearch = {};
        // },
        // [Mutations.SET_SEARCH](state, search) {
        //     state.search = search;
        // },
        [Mutations.SET_MATCH_PAYLOAD](state, search) {
            state.matchPayload = search;
        },
        [Mutations.SET_MATCHES_PAGENO](state: UserState, pageNo: number) {
            state.matches.pageNo = pageNo;
        },
        [Mutations.RESET_MATCHES_PAGINATION](state: UserState) {
            state.matches = createPaginationState();
        },
        [Mutations.SET_MATCHES](state: UserState, payload: { items: User[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.matches.items = payload.items;
            state.matches.itemCount = payload.itemCount;
            state.matches.pageSize = payload.pageSize;
            state.matches.pageNo = payload.pageNo;
            state.matches.pageCount = payload.pageCount;
        },
        [Mutations.PURGE_MATCH_USER](state) {
            state.matchUser = {};
        },
        [Mutations.PURGE_MATCHES](state) {
            state.matches = createPaginationState();
        },
        // [Mutations.SET_ERROR](state) {
        //     state.errors = {...error};
        // }
    }
};
